export function convertSize(size, fromUnit, toUnit) {
    const units = ['B', 'KB', 'MB', 'GB'];

    if (!units.includes(fromUnit) || !units.includes(toUnit)) {
        return 'Invalid units. Valid units are: B, KB, MB, GB';
    }

    // Conversion rates
    const conversionRates = {
        'B': 1,
        'KB': 1024,
        'MB': 1024 * 1024,
        'GB': 1024 * 1024 * 1024
    };

    // Convert from the original unit to bytes
    const sizeInBytes = size * conversionRates[fromUnit];

    // Convert from bytes to the target unit
    const convertedSize = sizeInBytes / conversionRates[toUnit];

    return Math.round(convertedSize);
}