import React, { useState, useMemo } from "react";
import TableUser from "./TableUser";
import { useDispatch } from "react-redux";
import { setLang } from "../redux/lang";
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json";

function Navigation() {
  const dispatch = useDispatch();
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);
  const [open, setOpen] = useState(false);

  const OpenModal = () => {
    setOpen(!open);
  };

  const ChangLang = (lang) => {
    console.log(lang, 'lang');
    dispatch(setLang(lang));
  };

  return null;
}

function Table() {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "First Name",
            accessor: "firstName",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
          },
        ],
      },
      {
        Header: "Info",
        columns: [
          {
            Header: "Age",
            accessor: "age",
            width: 50,
          },
          {
            Header: "Visits",
            accessor: "visits",
            width: 60,
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Profile Progress",
            accessor: "progress",
          },
        ],
      },
    ],
    []
  );

  return (
    <div>
      <main>
        <section className="projects section-padding" id="section_4">
          <div className="container">
            <div className="row" style={{ marginTop: 50 }}>
              <div
                className="col-lg-8 col-md-8 col-12 ms-auto"
                style={{ width: 1900 }}
              >
                <div
                  className="section-title-wrap d-flex justify-content-center align-items-center mb-4"
                  style={{ backgroundColor: "#1397cf" }}
                >
                  <h2 className="text-white ms-4 mb-0">
                    {langData['students_data']}
                  </h2>
                </div>
              </div>
              <div className="clearfix" />
              <TableUser columns={columns} />
            </div>
          </div>
        </section>
        <section
          className="contact section-padding"
          style={{ marginTop: "-100px" }}
        >
          <div className="container">
            <div className="row" style={{ marginTop: 0 }}>
              <div className="col-lg-6 col-md-8 col-12">
                <div
                  className="section-title-wrap d-flex justify-content-center align-items-center mb-5"
                  style={{ paddingRight: 90 }}
                >
                  <h2 className="text-white ms-4 mb-0">{langData['contact_us']}</h2>
                </div>
              </div>
              <div className="clearfix" />
              <div className="col-lg-3 col-md-6 col-12 pe-lg-0">
                <div className="contact-info contact-info-border-start d-flex flex-column">
                  <strong className="site-footer-title d-block mt-4 mb-3">
                    {langData['follow_us']}
                  </strong>
                  <ul className="social-icon">
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-envelope" />
                    </li>
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-instagram" />
                    </li>
                    <li className="social-icon-item">
                      <a
                        href="https://t.me/technoconsult_de"
                        className="social-icon-link bi-telegram"
                      />
                    </li>
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-facebook" />
                    </li>
                  </ul>
                  <strong className="site-footer-title d-block mt-4 mb-3">
                    Techno Consult
                  </strong>
                  <p className="mb-0">
                    {langData['learn_withus']}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 ps-lg-0">
                <div className="contact-info d-flex flex-column">
                  <strong className="site-footer-title d-block mb-3">
                    {langData['main_branch']}
                  </strong>
                  <p className="mb-2">
                    Namangan shahri, A.Xo'jayev k. 38A-uy. "Ishga marhamat"
                    monomarkaz binosi
                  </p>
                  <strong className="site-footer-title d-block mt-4 mb-3">
                    {langData['mail']}
                  </strong>
                  <p>
                    <a href="mailto:hello@josh.design">admin@technoconsult.uz</a>
                  </p>
                  <strong className="site-footer-title d-block mt-4 mb-3">
                    {langData['number']}
                  </strong>
                  <p className="mb-0">
                    <a href="tel: 99 995 50 36">+998(99)995-50-36</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                <form action="#" method="get" className="custom-form contact-form" role="form">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input type="text" name="name" id="name" className="form-control" placeholder="Name" required />
                        <label htmlFor="name">{langData['full_name']}</label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input type="email" name="email" id="email" className="form-control" placeholder="Email address" required />
                        <label htmlFor="email">{langData['mail']}</label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input type="number" name="number" id="number" className="form-control" placeholder="Phone number" required />
                        <label htmlFor="number">{langData['number']}</label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input type="number" name="age" id="age" className="form-control" placeholder="Age" required />
                        <label htmlFor="age">{langData['age']}</label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-12">
                      <div className="form-floating">
                        <textarea className="form-control" id="message" name="message" placeholder="Tell me about the project" defaultValue="" />
                        <label htmlFor="message">{langData['message']}</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 ms-auto">
                      <button type="submit" className="form-control">{langData['send']}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section id="copy-right">
          <div className="copy-right-sec">
            <i className="fa fa-copyright" />
            TechnoConsult 2024 Powered By{" "}
            <a href="https://t.me/Abubak1r">Qodirov Abubakr</a>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Table;
