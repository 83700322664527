import {
  Routes,
  Route,
  BrowserRouter,
  NavLink,
  useNavigate,
} from "react-router-dom";
import React, { useState, useMemo } from "react";

import "./App.css";
import About from "./component/about";
import Branches from "./component/branches";
import Contact from "./component/contact";
import Resume from "./component/resume";
import Cources from "./component/cources";
import News from "./component/news";
import Table from "./component/table";
import Partners from "./component/partners";

import BRAND from "./images/brend-logo.png";
import HEADER from "./images/resume-anketa.jpg";
import about_img from "./images/about-img.jpg";
import team1 from "./images/team-1.png";
import team6 from "./images/team-6.png";
import team2 from "./images/team-2.png";
import team3 from "./images/team-3.png";
import team4 from "./images/team-4.png";
import team5 from "./images/team-5.png";
import icon1 from "./images/icons/elchixona-1.png";
import icon2 from "./images/icons/hokimlik-1.png";
import icon3 from "./images/icons/kambagallikni-qisqartirish.png";
import icon4 from "./images/icons/migratsiya.png";
import icon5 from "./images/icons/monomarkaz1.png";
import { useDispatch } from "react-redux";
import { setLang } from "./redux/lang";
import { useLang } from "./redux/selectors";
import localData from "./redux/localization/locale.json";

function Navigation({ card_workers }) {
  const dispatch = useDispatch();
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);
  const [open, setOpen] = useState(false);

  const OpenModal = () => {
    setOpen(!open);
  };

  const ChangLang = (lang) => {
    console.log(lang, "lang");
    dispatch(setLang(lang));
  };
  console.log("==========lang==========================");
  console.log(lang);
  console.log("=============lang=======================");
  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{ position: "fixed", zIndex: 200 }}
    >
      <div className="container">

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={open}
          aria-label="Toggle navigation"
          onClick={OpenModal}
        >
          <span className="navbar-toggler-icon" />
        </button>

        <NavLink to="/" className="navbar-brand mx-auto mx-lg-0">
          <img src={BRAND} alt="" />
        </NavLink>
        <div
          className={`collapse navbar-collapse ${open && "show_mobile"}`}
          id="navbarNav"
        >
          <div className="navbar-nav ms-lg-5">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                {langData["home"]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                {langData["about"]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/cources">
                {langData["course"]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/partners">
                {langData["branches"]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                {langData["contact"]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/branches">
                {langData["partners"]}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/News">
                {langData["news"]}
              </NavLink>
            </li>
          </div>

          <div className="dropdown">
            <div class="custom-select-language">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-world"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#fff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                  <path d="M3.6 9h16.8" />
                  <path d="M3.6 15h16.8" />
                  <path d="M11.5 3a17 17 0 0 0 0 18" />
                  <path d="M12.5 3a17 17 0 0 1 0 18" />
                </svg>
              </div>
              <div>
                <select
                  className="select_language"
                  onChange={(e) => ChangLang(e.target.value)}
                >
                  <option value="uz">UZ</option>
                  <option value="ru">RU</option>
                  <option value="de">DE</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

function HomePage({ card_workers }) {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <main>
          <section
            style={{ backgroundColor: "#C4E4FF" }}
            className="hero d-flex justify-content-center align-items-center"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-12">
                  <div className="hero-text">
                    <div className="header-txt">
                      <h1
                        className="header-text"
                        style={{
                          fontSize: 50,
                          color: "#313c85",
                          marginTop: "-50px",
                        }}
                      >
                        {langData["header"]}
                      </h1>
                      <h1
                        className="header-text-bold"
                        style={{ fontSize: 50, color: "#313c85" }}
                      >
                        {langData["futbol"]}
                      </h1>
                      <h1
                        className="header-text-bold"
                        style={{ fontSize: 50, color: "#313c85" }}
                      >
                        {langData["voleybol"]}
                      </h1>
                      <h1
                        className="header-text"
                        style={{ fontSize: 50, color: "#313c85" }}
                      >
                        {langData["gandbol"]}
                      </h1>
                      <h3
                        className="header-text-sml"
                        style={{
                          fontSize: 20,
                          color: "#313c85",
                          letterSpacing: "0.5px",
                        }}
                      >
                        <span style={{ color: "rgb(5, 5, 107)", fontSize: 25 }}>
                          Techno{" "}
                          <span style={{ color: "rgb(189, 32, 32)" }}>
                            Consult
                          </span>{" "}
                        </span>
                        {langData["fizra"]} <br /> {langData["jismon"]}
                      </h3>
                    </div>
                    <p className="mb-4">
                      <button
                        onClick={() => {
                          navigate("/resume/all");
                        }}
                        class="button-58"
                        role="button"
                      >
                        {langData["student_data"]}
                      </button>
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-12 position-relative">
                  <img
                    onClick={() => {
                      navigate("/resume/create");
                    }}
                    src={HEADER}
                    className="hero-image img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#fff"
                fillOpacity={1}
                d="M0,160L24,160C48,160,96,160,144,138.7C192,117,240,75,288,64C336,53,384,75,432,106.7C480,139,528,181,576,208C624,235,672,245,720,240C768,235,816,213,864,186.7C912,160,960,128,1008,133.3C1056,139,1104,181,1152,202.7C1200,224,1248,224,1296,197.3C1344,171,1392,117,1416,90.7L1440,64L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"
              ></path>
            </svg>
          </section>
          <section className="about section-padding a-con">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <img
                    src={about_img}
                    className="about-image img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                  <div className="about-thumb">
                    <div
                      className="section-title-wrap d-flex justify-content-end align-items-center mb-4"
                      style={{ backgroundColor: "#1397cf" }}
                    >
                      <h2
                        className="text-white me-4 mb-0"
                        style={{ paddingRight: 100 }}
                      >
                        {langData["about_us"]}
                      </h2>
                    </div>
                    <p>{langData["about_txt1"]}</p>
                    <p>{langData["about_txt2"]}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="projects section-padding" id="section_4">
            <div className="container">
              <div className="row" style={{ marginTop: "-20px" }}>
                <div
                  className="col-lg-8 col-md-8 col-12 ms-auto"
                  style={{ width: 1900 }}
                >
                  <div
                    className="section-title-wrap d-flex justify-content-center align-items-center mb-4"
                    style={{ backgroundColor: "#1397cf" }}
                  >
                    <h2 className="text-white ms-4 mb-0">
                      {langData["our_team"]}
                    </h2>
                  </div>
                </div>
                <div className="clearfix" />

                {card_workers.map(({ id, fullName, image, pos }) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-12" key={id}>
                      <div className="projects-thumb" id="projects-thumb">
                        <div className="projects-info">
                          <small className="projects-tag">{pos}</small>
                          <h3 className="projects-title">{fullName}</h3>
                        </div>
                        <img src={image} className="img-fluid" alt={fullName} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <section className="services section-padding" id="section_3">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-12 mx-auto">
                  <div className="section-title-wrap d-flex justify-content-center align-items-center mb-5">
                    <h2 className="text-white ms-4 mb-0">
                      {langData["our_branches"]}
                    </h2>
                  </div>
                  <div className="row pt-lg-5">
                    <div className="col-lg-6 col-12">
                      <div className="services-thumb">
                        <div className="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                          <h3 className="mb-0">{langData["nam"]}</h3>
                        </div>
                        <p style={{ color: "black" }}>
                          <i className="bi bi-geo-alt" />
                          <span style={{ fontWeight: "bolder" }}>
                            {langData["location"]}:
                          </span>{" "}
                          Namangan shahri, A.Xo'jayev k. 38A-uy. "Ishga
                          marhamat" monomarkaz binosi
                          <br /> <br /> <i className="bi bi-telephone" />{" "}
                          <span style={{ fontWeight: "bolder" }}>
                            {langData["number"]}:
                          </span>{" "}
                          <br />
                          +998(99)995-50-36 <br /> +998(99)534-43-44
                          <br />{" "}
                          <a href style={{ color: "rgb(0, 76, 255)" }}>
                            <br />
                            <i className="bi bi-envelope" />
                          </a>
                          <a
                            href="https://t.me/technoconsult_de"
                            style={{ color: "rgb(0, 76, 255)" }}
                          >
                            <i className="bi bi-facebook" />
                          </a>
                          <a href="#" style={{ color: "rgb(0, 76, 255)" }}>
                            <i className="bi bi-telegram" />
                          </a>
                          <a href="#" style={{ color: "rgb(0, 76, 255)" }}>
                            <i className="bi bi-instagram" />{" "}
                          </a>
                        </p>
                        <div className="services-icon-wrap d-flex justify-content-center align-items-center">
                          <i className="services-icon bi bi-geo-alt" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="services-thumb services-thumb-up">
                        <div className="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                          <h3 className="mb-0">{langData["tosh"]}</h3>
                        </div>
                        <p style={{ color: "black" }}>
                          <i className="bi bi-geo-alt" />
                          <span style={{ fontWeight: "bolder" }}>
                            {langData["location"]}:
                          </span>{" "}
                          Chilonzor tumani, Dombrobod ko'chasi, 74-uy
                          <br /> <br /> <i className="bi bi-telephone" />
                          {langData["number"]}: <br />
                          +998(94)700-75-80
                          <br />{" "}
                          <a href style={{ color: "rgb(0, 76, 255)" }}>
                            {" "}
                            <br />
                            <i className="bi bi-envelope" />
                          </a>
                          <a href="#" style={{ color: "rgb(0, 76, 255)" }}>
                            <i className="bi bi-facebook" />
                          </a>
                          <a
                            href="https://t.me/technoconsult_de"
                            style={{ color: "rgb(0, 76, 255)" }}
                          >
                            <i className="bi bi-telegram" />
                          </a>
                          <a href="#" style={{ color: "rgb(0, 76, 255)" }}>
                            <i className="bi bi-instagram" />{" "}
                          </a>
                        </p>
                        <div className="services-icon-wrap d-flex justify-content-center align-items-center">
                          <i className="services-icon bi-globe" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="services-thumb">
                        <div className="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                          <h3 className="mb-0">{langData["far"]}</h3>
                        </div>
                        <p style={{ color: "black" }}>
                          <i className="bi bi-geo-alt" />
                          <span style={{ fontWeight: "bolder" }}>
                            {langData["location"]}:
                          </span>{" "}
                          Farg'ona shahri, S.Temur ko'chasi, 245-uy
                          <br /> <br /> <i className="bi bi-telephone" />{" "}
                          <span style={{ fontWeight: "bolder" }}>
                            {langData["number"]}:
                          </span>{" "}
                          <br />
                          +998(94)700-70-83
                          <br />{" "}
                          <a href style={{ color: "rgb(0, 76, 255)" }}>
                            <br />
                            <i className="bi bi-envelope" />
                          </a>
                          <a href="#" style={{ color: "rgb(0, 76, 255)" }}>
                            <i className="bi bi-facebook" />
                          </a>
                          <a
                            href="https://t.me/technoconsult_de"
                            style={{ color: "rgb(0, 76, 255)" }}
                          >
                            <i className="bi bi-telegram" />
                          </a>
                          <a href="#" style={{ color: "rgb(0, 76, 255)" }}>
                            <i className="bi bi-instagram" />{" "}
                          </a>
                        </p>
                        <div className="services-icon-wrap d-flex justify-content-center align-items-center">
                          <i className="services-icon bi-globe" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="services-thumb services-thumb-up">
                        <div className="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                          <h3 className="mb-0">{langData["and"]}</h3>
                        </div>
                        <p style={{ color: "black" }}>
                          <i className="bi bi-geo-alt" />
                          <span style={{ fontWeight: "bolder" }}>
                            {langData["location"]}:
                          </span>{" "}
                          Andijon shahri, Boburshoh kochasi, 4-uy
                          <br /> <br /> <i className="bi bi-telephone" />{" "}
                          <span style={{ fontWeight: "bolder" }}>
                            {langData["number"]}:
                          </span>{" "}
                          <br /> +998(94)700-75-33
                          <br />{" "}
                          <a href style={{ color: "rgb(0, 76, 255)" }}>
                            <br />
                            <i className="bi bi-envelope" />
                          </a>
                          <a href="#" style={{ color: "rgb(0, 76, 255)" }}>
                            <i className="bi bi-facebook" />
                          </a>
                          <a
                            href="https://t.me/technoconsult_de"
                            style={{ color: "rgb(0, 76, 255)" }}
                          >
                            <i className="bi bi-telegram" />
                          </a>
                          <a href="#" style={{ color: "rgb(0, 76, 255)" }}>
                            <i className="bi bi-instagram" />{" "}
                          </a>
                        </p>
                        <div className="services-icon-wrap d-flex justify-content-center align-items-center">
                          <i className="services-icon bi-geo-alt" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="clients section-padding">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-12 col-12"
                  style={{ marginTop: "-30px" }}
                >
                  <h3 className="text-center mb-5">
                    {langData["our_partners"]}
                  </h3>
                </div>
                <div
                  className="col-lg-2 col-4 ms-auto clients-item-height"
                  style={{ textAlign: "center" }}
                >
                  <a href="https://mehnat.uz/oz/employees">
                    <img
                      src={icon3}
                      className="clients-image img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div
                  className="col-lg-2 col-4 clients-item-height"
                  style={{ textAlign: "center" }}
                >
                  <a href="http://www.migration.uz/">
                    <img
                      src={icon4}
                      className="clients-image img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div
                  className="col-lg-2 col-4 clients-item-height"
                  style={{ textAlign: "center" }}
                >
                  <a href="https://monomarkaz.uz/">
                    <img src={icon5} className="clients-image img-fluid" alt />
                  </a>
                </div>
                <div
                  className="col-lg-2 col-4 me-auto clients-item-height"
                  style={{ textAlign: "center" }}
                >
                  <a href="https://gov.uz/namangan/">
                    <img
                      src={icon2}
                      className="clients-image img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="contact section-padding" id="section_5">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 col-md-8 col-12"
                  style={{ marginTop: "-60px" }}
                >
                  <div
                    className="section-title-wrap d-flex justify-content-center align-items-center mb-5"
                    style={{ paddingRight: 90 }}
                  >
                    <h2 className="text-white ms-4 mb-0">
                      {langData["contact_us"]}
                    </h2>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-lg-3 col-md-6 col-12 pe-lg-0">
                  <div className="contact-info contact-info-border-start d-flex flex-column">
                    <strong className="site-footer-title d-block mt-4 mb-3">
                      {langData["follow_us"]}
                    </strong>
                    <ul className="social-icon">
                      <li className="social-icon-item">
                        <a href="#" className="social-icon-link bi-envelope" />
                      </li>
                      <li className="social-icon-item">
                        <a href="#" className="social-icon-link bi-instagram" />
                      </li>
                      <li className="social-icon-item">
                        <a
                          href="https://t.me/technoconsult_de"
                          className="social-icon-link bi-telegram"
                        />
                      </li>
                      <li className="social-icon-item">
                        <a href="#" className="social-icon-link bi-facebook" />
                      </li>
                    </ul>
                    <strong className="site-footer-title d-block mt-4 mb-3">
                      Techno Consult
                    </strong>
                    <p className="mb-0">{langData["learn_withus"]}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 ps-lg-0">
                  <div className="contact-info d-flex flex-column">
                    <strong className="site-footer-title d-block mb-3">
                      {langData["main_branch"]}
                    </strong>
                    <p className="mb-2">
                      Namangan shahri, A.Xo'jayev k. 38A-uy. "Ishga marhamat"
                      monomarkaz binosi
                    </p>
                    <strong className="site-footer-title d-block mt-4 mb-3">
                      {langData["mail"]}
                    </strong>
                    <p>
                      <a href="mailto:hello@josh.design">
                      admin@technoconsult.uz
                      </a>
                    </p>
                    <strong className="site-footer-title d-block mt-4 mb-3">
                      {langData["number"]}
                    </strong>
                    <p className="mb-0">
                      <a href="tel: 99 995 50 36">+998(99)995-50-36</a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                  <form action="#" method="get" className="custom-form contact-form" role="form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-floating">
                          <input type="text" name="name" id="name" className="form-control" placeholder="Name" required />
                          <label htmlFor="name">{langData['full_name']}</label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-floating">
                          <input type="email" name="email" id="email" className="form-control" placeholder="Email address" required />
                          <label htmlFor="email">{langData['mail']}</label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-floating">
                          <input type="number" name="number" id="number" className="form-control" placeholder="Phone number" required />
                          <label htmlFor="number">{langData['number']}</label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-floating">
                          <input type="number" name="age" id="age" className="form-control" placeholder="Age" required />
                          <label htmlFor="age">{langData['age']}</label>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12">
                        <div className="form-floating">
                          <textarea className="form-control" id="message" name="message" placeholder="Tell me about the project" defaultValue="" />
                          <label htmlFor="message">{langData['message']}</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12 ms-auto">
                        <button type="submit" className="form-control">{langData['send']}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/*footer start*/}
        <div className="footer">
          <footer id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <a href="index.html">
                    <img src={BRAND} alt="" className="img-fluid logo-footer" />
                  </a>
                </div>
                <div className="col-md-3">
                  <div className="useful-link">
                    <h6>{langData["navigation"]}</h6>
                    <div className="use-links">
                      <li>
                        <a href="index.html">
                          <i className="bi bi-arrow-right" /> {langData["home"]}
                        </a>
                      </li>
                      <li>
                        <a href="about.html">
                          <i className="bi bi-arrow-right" />{" "}
                          {langData["about"]}
                        </a>
                      </li>
                      <li>
                        <a href="course.html">
                          <i className="bi bi-arrow-right" />{" "}
                          {langData["course"]}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="bi bi-arrow-right" />{" "}
                          {langData["branches"]}
                        </a>
                      </li>
                      <li>
                        <a href="contact.html">
                          <i className="bi bi-arrow-right" />{" "}
                          {langData["contact"]}
                        </a>
                      </li>
                      <li>
                        <a href="branches.html">
                          <i className="bi bi-arrow-right" />{" "}
                          {langData["partners"]}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="bi bi-arrow-right" /> {langData["news"]}
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="social-links">
                    <h6>
                      {langData["to_social"]} <br /> {langData["media"]} <br />{" "}
                      {langData["links"]}
                    </h6>

                    <div className="social-icons">
                      <li>
                        <a href>
                          <i className="bi bi-facebook" /> Facebook
                        </a>
                      </li>
                      <li>
                        <a href>
                          <i className="bi bi-instagram" /> Instagram
                        </a>
                      </li>
                      <li>
                        <a href>
                          <i className="bi bi-telegram" /> Telegram
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="address">
                    <h6>{langData["location"]}</h6>
                    <div className="address-links">
                      <li>
                        <a href>
                          <i className="bi bi-geo-alt" /> Namangan shahar-{" "}
                          <br />
                          A.Xo'jayev k. 38A-uy. "Ishga marhamat" monomarkaz
                          binosi
                        </a>
                      </li>
                      <li>
                        <a href>
                          <i className="bi bi-telephone" /> +998(99)995-50-36
                        </a>
                      </li>
                      <li>
                        <a href>
                          <i className="bi bi-envelope" /> info@gmail.com
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          {/* footer section end */}
          {/* footer copy right section start */}
          <section id="copy-right">
            <div className="copy-right-sec">
              <i className="fa fa-copyright" />
              TechnoConsult 2024 Powerd By{" "}
              <a href="https://t.me/Abubak1r">Qodirov Abubakr</a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

function App() {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);

  const card_workers = [
    {
      id: 1,
      fullName: "Karl Heinz Jach",
      pos: langData["team_1"],
      image: team1,
    },
    {
      id: 1,
      fullName: "Xashimov Zuxriddin",
      pos: langData["team_2"],
      image: team6,
    },
    {
      id: 1,
      fullName: "Turg'unov Mahmudjon",
      pos: langData["team_3"],
      image: team2,
    },
    {
      id: 1,
      fullName: "Madina Ibragimova",
      pos: langData["team_4"],
      image: team3,
    },
    {
      id: 1,
      fullName: "Abduğaniyeva Surayo",
      pos: langData["team_5"],
      image: team4,
    },
    {
      id: 1,
      fullName: "Jalolxonova Komila",
      pos: langData["team_6"],
      image: team5,
    },
  ];

  return (
    <>
      <BrowserRouter>
        <Navigation card_workers={card_workers} />
        <Routes>
          <Route path="/" element={<HomePage card_workers={card_workers} />} />
          <Route path="/news" element={<News workers={card_workers} />} />
          <Route path="/about" element={<About workers={card_workers} />} />
          <Route
            path="/branches"
            element={<Branches workers={card_workers} />}
          />
          <Route path="/contact" element={<Contact workers={card_workers} />} />
          <Route path="/cources" element={<Cources workers={card_workers} />} />
          <Route
            path="/partners"
            element={<Partners workers={card_workers} />}
          />
          <Route
            path="/resume/all"
            element={<Table workers={card_workers} />}
          />
          <Route
            path="/resume/create"
            element={<Resume workers={card_workers} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
