import { createSlice } from "@reduxjs/toolkit";
const initialState = "uz";

export const langSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLang: (state, { payload }) => {
      localStorage.setItem("lang", payload);
      return (state = payload);
    },
  },
});

export const { setLang } = langSlice.actions;

export default langSlice.reducer;
