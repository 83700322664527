import React, { useState, useMemo } from "react";
import axios from "axios";
import Editor from 'react-simple-wysiwyg';
import { useForm, Controller } from 'react-hook-form';
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json";
import { convertSize } from "../utils";


function ResumeForm() {
  const lang = useLang();
  const { register, handleSubmit, reset, control, formState: { errors } } = useForm();
  const langData = useMemo(() => localData[lang], [lang]);
  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreviewFile, setImagePreviewFile] = useState(null);
  const [imagePre, setImagePre] = useState(null);
  const [imagePreFile, setImagePreFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState({});
  const [html, setHtml] = useState('my <b>HTML</b>');

  const onSubmit = (data) => {
    delete data.image;
    delete data.file1;
    delete data.file2;
    data.html_code = html;
    // Create a FormData object
    const formData = new FormData();

    // Append form data
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    // Append the selected file
    if (imagePreview) {
      formData.append('img', imagePreview);
    }
    if (imagePreviewFile) {
      formData.append('imge', imagePreviewFile);
    }

 

    setIsLoading(true)
    axios.post('https://admin.technoconsult.uz/api/resume', formData, {
      cache: false,
      contentType: false,
      processData: false,
    })
      .then(({ _ }) => {
        setIsLoading(false);
        reset();
        setImagePreview(null)
        setImagePre(null)
        alert('Your RESUME has been sent successfully')
      })
      .catch(error => {
        console.error('There was an error uploading the file!', error);
        setIsLoading(false)
        alert('Error create resume !')
      });
  };


  const handleFileChange = (e, name) => {
    const newFiles = { ...files, [name]: e.target.files[0] };
    const { file1 = null, file2 = null } = newFiles;

    if (file1) {
      const fileSize = convertSize(file1.size, 'B', 'KB', 'MB', 'GB');

      if (fileSize > 20) return alert('katta')
      console.log(fileSize, 'fileSize');
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePre(reader.result);
      };
      reader.readAsDataURL(file1);
      setImagePreview(file1);
    }

    if (file2) {
      const fileSize = convertSize(file2.size, 'B', 'KB', 'MB', 'GB');

      if (fileSize > 100) return alert('katta')
      console.log(fileSize, 'fileSize');
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreFile(reader.result);
      };
      reader.readAsDataURL(file2);
      setImagePreviewFile(file2)
    }
    setFiles(newFiles);
  };


  function onChange(e) {
    setHtml(e.target.value);
  }

  return (
    <div>
      <h2 className="text-black ms-4 mb-0"> {langData['create_resumetxt']}</h2>
      <div className="rootResume">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="resumeName">
            <div className="resume-img-wrapper">
              <label htmlFor="imageUpload" className={`resume-img ${errors.image ? 'input-error' : ''}`}>
                {imagePre ? (
                  <img src={imagePre} alt="Preview" />
                ) : (
                  '3x4 Photo max-(20kb)'
                )}
                {errors.image && <span className="error-message">Image is required</span>}
              </label>
              <Controller
                name="file1"
                control={control}
                render={({ field }) => (
                  <input
                    type="file"
                    id="imageUpload"
                    onChange={(e) => {
                      handleFileChange(e, 'file1');
                      field.onChange(e);
                    }}
                    style={{ display: 'none' }}
                  />
                )}
              />
            </div>

            <div className="resumeInputs">
              <div className="name">
                <div className="form-floating">
                  <input
                    type="text"
                    name="name"
                    id="firstName"
                    className={`form-control ${errors.name ? 'input-error' : ''}`}
                    placeholder="Ism"
                    {...register('name', { required: true })}
                  />
                  <label htmlFor="name">Name</label>
                  {errors.name && ""}
                </div>
              </div>
              <div className="surname">
                <div className="form-floating">
                  <input
                    type="text"
                    name="surname"
                    id="surname"
                    className={`form-control ${errors.surname ? 'input-error' : ''}`}
                    placeholder="Familiya"
                    {...register('surname', { required: true })}
                  />
                  <label htmlFor="surname">Nachname</label>
                  {errors.surname && ""}
                </div>
              </div>
              <div className="fathersname">
                <div className="form-floating">
                  <input
                    type="text"
                    name="patronymic"
                    className={`form-control ${errors.patronymic ? 'input-error' : ''}`}
                    placeholder="Otasining ismi"
                    {...register('patronymic', { required: true })}
                  />
                  <label htmlFor="patronymic">Der Name seines Vaters</label>
                  {errors.patronymic && ""}
                </div>
              </div>

            </div>
          </div>
          <div className="selecters">
            <p className="textSelecter">{langData['speciality']}</p>
            <select name="task" className="select form-floating" {...register('task')}>
              <option value="1">Fahrdienst</option>
              <option value="2" selected>Kurier</option>
              <option value="3">Metallurgische Industrie</option>
              <option value="4">Babysitter-Service</option>
              <option value="5">Kochservice</option>
              <option value="6">IT-Technologie</option>
              <option value="7">Bauservice</option>
              <option value="8">Eisenbahnbau</option>
              <option value="9">Mechatroniker</option>
              <option value="10">Tekistil-Bereich</option>
              <option value="11">Kosmetologie</option>
              <option value="12">Handelssektor</option>
              <option value="13">Catering, Gastronomie</option>
              <option value="14">Tourismus Industrie</option>
              <option value="15">Landwirtschaftssektor</option>
              <option value="16">Medizinisches Personal der mittleren Ebene</option>
              <option value="17">Elektrizitä</option>
              <option value="18">Anderes Feld</option>
            </select>

            <div className="fathersname nameee">
              <div className="form-floating">
                <input
                  type="textarea"
                  name="text"
                  id="text"
                  className={`form-control ${errors.email ? 'input-error' : ''}`}
                  placeholder="Qo'shimcha mutahassisligi"
                  {...register('email', { required: false })}
                />
                <label htmlFor="email">Zusätzliche Spezialität</label>
                {errors.email && ""}
              </div>
            </div>
            <div className="name">
              <div className="form-floating" style={{ marginTop: "20px" }}>
                <input
                  type="text"
                  name="nationality"
                  id="firstName"
                  className={`form-control ${errors.nationality ? 'input-error' : ''}`}
                  placeholder="Fuqaroligi"
                  {...register('nationality', { required: true })}
                />
                <label htmlFor="nationality">Staatsbürgerschaft</label>
                {errors.nationality && ""}
              </div>
            </div>

            <div className="name">
              <div className="form-floating" style={{ marginTop: "20px" }}>
                <input
                  type="text"
                  name="Address"
                  id="Address"
                  className={`form-control ${errors.Address ? 'input-error' : ''}`}
                  placeholder="Address"
                  {...register('Address', { required: true })}
                />
                <label htmlFor="Address">Wohnadresse</label>
                {errors.Address && ""}
              </div>
            </div>

            <div className="form-floating" style={{ marginTop: "20px", }}>
              <p style={{ fontSize: "15px" }}>Geburtsdatum</p>
              <input type="date" name="age" {...register('age', { required: true })} style={{ width: "100%", height: "50px", border: "1px solid #ced4da" }} />
            </div>
            <p className="textSelecter">Deutschkenntnisse</p>
            <select name="level" className="select form-floating" {...register('level')}>
              <option value="A1">{langData['a1level']}</option>
              <option value="A2" selected>{langData['a2level']}</option>
              <option value="B1">{langData['a3level']}</option>
              <option value="B2">{langData['a4level']}</option>
              <option value="Ein anderer">Anderes Feld</option>
            </select>
            <div className="fathersname nameee">
              <div className="form-floating">
                <input
                  type="text"
                  name="text"
                  id="text"
                  className={`form-control ${errors.textarea ? 'input-error' : ''}`}
                  placeholder="Qo'shimcha til bilishi"
                  {...register('text', { required: false })}
                />
                <label htmlFor="text">Zusätzliche Sprachkenntnisse</label>
                {errors.email && ""}
              </div>
            </div>
            <div className="fathersname nameee">
              <div className="form-floating">
                <input
                  type="tel"
                  name="phone"
                  className={`form-control ${errors.phone ? 'input-error' : ''}`}
                  placeholder="Telefon Raqam"
                  {...register('phone', { required: true })}
                />
                <label htmlFor="phone">Telefonnummer</label>
                {errors.phone && ""}
              </div>
            </div>
            <div className="fathersname nameee">
              <div className="form-floating">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={`form-control ${errors.email ? 'input-error' : ''}`}
                  placeholder="Pochta Manzili"
                  {...register('email', { required: true })}
                />
                <label htmlFor="email">Email</label>
                {errors.email && ""}
              </div>
            </div>
            <label htmlFor="floatingTextarea" style={{ marginTop: 20 }}>Arbeitstätigkeit</label>
            <div className="form-floating" style={{ marginTop: "5px", }}>
              {/*               <textarea
                className="form-control"
                id="message"
                placeholder="Tell me about the project"
                defaultValue={""}
                {...register('html_code', { required: true })}
                name="html_code"
              /> */}
              <Editor value={html} onChange={onChange}
                toolbar={{
                  bold: false,
                  italic: false,
                  underline: false,
                  strikeThrough: false, // Отключить зачеркнутый текст
                  heading: false,
                  list: false,
                  quote: false,
                  code: false, // Отключить вставку кода
                  link: false,
                  image: false,
                  video: false, // Отключить вставку видео
                  table: false, // Отключить вставку таблиц
                  color: false,
                  backgroundColor: false,
                  align: false,
                  undo: false,
                  redo: false
                }}
              />

            </div>
          </div>
          {/* <p style={{ fontSize: "15px" }}>Eine Momentaufnahme des Arbeitsprozesses</p>
          <Controller
            name="file2"
            control={control}
            render={({ field }) => (
              <input
                type="file"
                accept="image/*"
                style={{
                  display: 'block',
                  margin: '10px 0',
                  padding: '10px',
                  border: '2px solid #ccc',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
                onChange={(e) => {
                  handleFileChange(e, 'file2');
                  field.onChange(e);
                }}
              />
            )}
          />
          <p><span style={{ color: "black" }}>Format:</span> .jpg <span style={{ color: "black" }}>Max</span>-(100kb) <span style={{ color: "black" }}>Convert image: <a href="https://compressjpeg.com/" style={{ color: "blue", fontWeight: "200" }}>https://compressjpeg.com/</a></span></p> */}


          {isLoading ? 'Loading....' : <button type="submit" className="resume-btn">Versand</button>}
        </form>
      </div>

      <section className="contact section-padding" id="section_5">
        <div className="container">
          <div className="row" style={{ marginTop: 0 }}>
            <div className="col-lg-6 col-md-8 col-12">
              <div
                className="section-title-wrap d-flex justify-content-center align-items-center mb-5"
                style={{ paddingRight: 90 }}
              >
                <h2 className="text-white ms-4 mb-0">{langData['contact_us']}</h2>
              </div>
            </div>
            <div className="clearfix" />
            <div className="col-lg-3 col-md-6 col-12 pe-lg-0">
              <div className="contact-info contact-info-border-start d-flex flex-column">
                <strong className="site-footer-title d-block mt-4 mb-3">
                  {langData['follow_us']}
                </strong>
                <ul className="social-icon">
                  <li className="social-icon-item">
                    <a href="#" className="social-icon-link bi-envelope" />
                  </li>
                  <li className="social-icon-item">
                    <a href="#" className="social-icon-link bi-instagram" />
                  </li>
                  <li className="social-icon-item">
                    <a
                      href="https://t.me/technoconsult_de"
                      className="social-icon-link bi-telegram"
                    />
                  </li>
                  <li className="social-icon-item">
                    <a href="#" className="social-icon-link bi-facebook" />
                  </li>
                </ul>
                <strong className="site-footer-title d-block mt-4 mb-3">
                  Techno Consult
                </strong>
                <p className="mb-0">
                  {langData['learn_withus']}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 ps-lg-0">
              <div className="contact-info d-flex flex-column">
                <strong className="site-footer-title d-block mb-3">
                  {langData['main_branch']}
                </strong>
                <p className="mb-2">
                  Namangan shahri, A.Xo'jayev k. 38A-uy. "Ishga marhamat"
                  monomarkaz binosi
                </p>
                <strong className="site-footer-title d-block mt-4 mb-3">
                  {langData['mail']}
                </strong>
                <p>
                  <a href="mailto:hello@josh.design">admin@technoconsult.uz</a>
                </p>
                <strong className="site-footer-title d-block mt-4 mb-3">
                  {langData['number']}
                </strong>
                <p className="mb-0">
                  <a href="tel: 99 995 50 36">+998(99)995-50-36</a>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-5 mt-lg-0">
              <form action="#" method="get" className="custom-form contact-form" role="form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-floating">
                      <input type="text" name="name" id="name" className="form-control" placeholder="Name" required />
                      <label htmlFor="name">{langData['full_name']}</label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-floating">
                      <input type="email" name="email" id="email" className="form-control" placeholder="Email address" required />
                      <label htmlFor="email">{langData['mail']}</label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-floating">
                      <input type="number" name="number" id="number" className="form-control" placeholder="Phone number" required />
                      <label htmlFor="number">{langData['number']}</label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-floating">
                      <input type="number" name="age" id="age" className="form-control" placeholder="Age" required />
                      <label htmlFor="age">{langData['age']}</label>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-floating">
                      <textarea className="form-control" id="message" name="message" placeholder="Tell me about the project" defaultValue="" />
                      <label htmlFor="message">{langData['message']}</label>
                    </div>
                  </div>
                  <div className="col-lg-3 col-12 ms-auto">
                    <button type="submit" className="form-control">{langData['send']}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section id="copy-right">
        <div className="copy-right-sec">
          <i className="fa fa-copyright" />
          TechnoConsult 2024 Powered By <a href="https://t.me/Abubak1r">Qodirov Abubakr</a>
        </div>
      </section>
    </div>
  );
}

export default ResumeForm;
