import React, { useState, useEffect, useMemo } from "react";
import axios from 'axios';
import "./style.css";
import PDF from "./pdf.png";

import { useDispatch } from "react-redux";
import { setLang } from "../../redux/lang";
import { useLang } from '../../redux/selectors';
import localData from "../../redux/localization/locale.json"

function Navigation() {
    const dispatch = useDispatch();
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);
    const [open, setOpen] = useState(false);

    const OpenModal = () => {
        setOpen(!open);
    };

    const ChangLang = (lang) => {
        console.log(lang, 'lang');
        dispatch(setLang(lang));
    }

}

function Table({ columns, data = [] }) {
    const lang = useLang();
    const langData = useMemo(() => localData[lang], [lang]);
    const [resumeData, setResume] = useState([]);
    const [backup, setBackup] = useState([]);


    useEffect(() => {
        getAllResume();
    }, []);


    const getAllResume = () => {
        axios.get('https://admin.technoconsult.uz/api/resume')
            .then(({ data }) => {
                setResume(data);
                setBackup(data);
            })
            .catch(error => {

            });
    }

    const downloadFile = async (id) => {
        try {
            const response = await axios.get(`https://admin.technoconsult.uz/items/${id}/pdf`, {
                responseType: 'blob', // important
            });

            // Create a new Blob object using the response data of the file
            const file = new Blob([response.data], { type: 'application/pdf' });

            // Create a link element
            const link = document.createElement('a');
            // Set the download attribute with a filename
            link.href = URL.createObjectURL(file);
            link.download = 'downloaded-file.pdf';
            // Append the link to the body
            document.body.appendChild(link);
            // Programmatically click the link to trigger the download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);

        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    function createArrayFilter(initialArray) {
        let currentArray = [...initialArray];
        const originalArray = [...initialArray];

        function filterByPatronymic(substring) {
            if (!substring) return currentArray;
            const searchTerm = substring.toLowerCase();
            currentArray = originalArray.filter(item => {
                if (item.task === searchTerm) {
                    return true;
                }
                return false;
            });

            return currentArray;
        }

        function reset() {
            currentArray = [...originalArray];
            return currentArray;
        }

        return { filterByPatronymic, reset };
    }
    const arrayFilter = createArrayFilter(backup);

    const filterTableResume = (fil) => {
        if (fil === 'reset') {
            setResume(backup)
        } else {
            setResume(arrayFilter.filterByPatronymic(fil));
        }
    }
    return (
        <div className="container-table">
            <div className="controls">
                <select className="select_table" onChange={(e) => filterTableResume(e.target.value)}>
                    <option value="reset">All Resume</option>
                    <option value="1">{langData['select']['1']}</option>
                    <option value="2">{langData['select']['2']}</option>
                    <option value="3">{langData['select']['3']}</option>
                    <option value="4">{langData['select']['4']}</option>
                    <option value="5">{langData['select']['5']}</option>
                    <option value="6">{langData['select']['6']}</option>
                    <option value="7">{langData['select']['7']}</option>
                    <option value="8">{langData['select']['8']}</option>
                    <option value="9">{langData['select']['9']}</option>
                    <option value="10">{langData['select']['10']}</option>
                    <option value="11">{langData['select']['11']}</option>
                    <option value="12">{langData['select']['12']}</option>
                    <option value="13">{langData['select']['13']}</option>
                    <option value="14">{langData['select']['14']}</option>
                    <option value="15">{langData['select']['15']}</option>
                    <option value="16">{langData['select']['16']}</option>
                    <option value="17">{langData['select']['17']}</option>
                    <option value="18">{langData['select']['18']}</option>
                </select>
            </div>
            <div className="table-container">
                <table className="table-cont">
                    <thead className="table-header">
                        <tr className="table-header-row">
                            <th className="key-heading">No.</th>
                            <th className="details-heading">F.I.O</th>
                            <th className="status-heading">{langData['level']}</th>
                            <th className="date-heading">Mutahasisligi</th>
                            <th className="price-heading">{langData['download']}</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {resumeData.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td><a href="#">{index + 1}</a></td>
                                    <td>{item?.name} {item?.surname} {item?.patronymic}</td>
                                    <td><span className="shipped-status">{item?.level}</span></td>
                                    <td>{langData['select'][item?.task]}</td>
                                    <td>
                                        <a href="#" onClick={() => downloadFile(item.id)}>{langData['download']}</a>
                                        <img src={PDF} alt="" style={{ width: 27 }} />
                                    </td>
                                </tr>
                            )
                        })}


                    </tbody>
                </table>
            </div>
            <div className="grid-container">
                {resumeData.map((item, index) => {
                    return (
                        <div className="grid-item" key={item?.id}>
                            <div className="grid-top" style={{ display: 'table' }}>
                                <div>
                                    <a href="#" className="grid-a"></a>
                                </div>
                                <div className="cancelled-status">
                                    <span>{item?.level}</span>
                                </div>
                                <div className="grid-date">{item?.name} {item?.surname} {item?.patronymic}</div>
                            </div>
                            <div className="specialty">{langData['select'][item?.task]}i</div>
                            <div className="grid-desc-mobill">
                                <button className="grid-desc-mobil">
                                    <a href="#" onClick={() => downloadFile(item.id)}>{langData['download']}</a>
                                    <img src={PDF} alt="" style={{ width: 27 }} />
                                </button>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    );
}

export default Table;
