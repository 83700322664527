import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setLang } from "../redux/lang";
import { useLang } from '../redux/selectors';
import localData from "../redux/localization/locale.json";

import icon1 from "../images/icons/elchixona-1.png";
import icon2 from "../images/icons/hokimlik-1.png";
import icon3 from "../images/icons/kambagallikni-qisqartirish.png";
import icon4 from "../images/icons/migratsiya.png";
import icon5 from "../images/icons/monomarkaz1.png";
import about_germany from "../images/about-germany-1.png";

function Navigation() {
  const dispatch = useDispatch();
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);
  const [open, setOpen] = useState(false);

  const OpenModal = () => {
    setOpen(!open);
  };

  const ChangLang = (lang) => {
    console.log(lang, 'lang');
    dispatch(setLang(lang));
  };

  console.log('==========lang==========================');
  console.log(lang);
  console.log('=============lang=======================');
}

function Partners() {
  const lang = useLang();
  const langData = useMemo(() => localData[lang], [lang]);

  const [isReadMore, setIsReadMore] = useState(true);
  const [showMoreAboutGermany, setShowMoreAboutGermany] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    setShowMoreAboutGermany(false); // Reset state when toggling
  };

  const toggleAboutGermany2 = () => {
    setShowMoreAboutGermany(!showMoreAboutGermany);
  };



  return (
    <div>
      <main>
        <section className="clients section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-12" style={{ marginTop: 30 }}>
                <h3 className="text-center mb-5">{langData['our_partners']}</h3>
              </div>
              <div className="col-lg-2 col-4 ms-auto clients-item-height" style={{ textAlign: "center" }}>
                <a href="https://mehnat.uz/oz/employees">
                  <img src={icon3} className="clients-image img-fluid" alt="" />
                </a>
              </div>
              <div className="col-lg-2 col-4 clients-item-height" style={{ textAlign: "center" }}>
                <a href="http://www.migration.uz/">
                  <img src={icon4} className="clients-image img-fluid" alt="" />
                </a>
              </div>
              <div className="col-lg-2 col-4 clients-item-height" style={{ textAlign: "center" }}>
                <a href="https://monomarkaz.uz/">
                  <img src={icon5} className="clients-image img-fluid" alt="" />
                </a>
              </div>
              <div className="col-lg-2 col-4 me-auto clients-item-height" style={{ textAlign: "center" }}>
                <a href="https://gov.uz/namangan/">
                  <img src={icon2} className="clients-image img-fluid" alt="" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <div>
          <main>
            {/* Existing code */}
            <section className="about section-padding">
              <div className="container a-con" style={{ marginTop: "-140px" }}>
                <div className="row" style={{ marginTop: 100 }}>
                  <div className="col-lg-6 col-12">
                    <img src={about_germany} className="about-image img-fluid" alt="" />
                  </div>
                  <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                    <div className="about-thumb">
                      <div className="section-title-wrap d-flex justify-content-end align-items-center mb-4" style={{ backgroundColor: "#1397cf" }}>
                        <h2 className="text-white me-4 mb-0" style={{ paddingRight: 100 }}>
                          {langData['about_germany']}
                        </h2>
                      </div>
                      <div className="read-txt">
                        <p>
                          {langData['about_germany1']}
                          <span className={`${isReadMore && 'show'}`}>
                            {langData['about_germany2']}
                          </span>
                        </p>
                        <button id="readMore" className="readMore custom-btn" onClick={() => setIsReadMore(!isReadMore)}>{langData['read_more']}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Existing code */}
          </main>
        </div>
        <section className="contact section-padding" style={{ marginTop: "-100px" }}>
          <div className="container">
            <div className="row" style={{ marginTop: 0 }}>
              <div className="col-lg-6 col-md-8 col-12">
                <div className="section-title-wrap d-flex justify-content-center align-items-center mb-5" style={{ paddingRight: 90 }}>
                  <h2 className="text-white ms-4 mb-0">{langData['contact_us']}</h2>
                </div>
              </div>
              <div className="clearfix" />
              <div className="col-lg-3 col-md-6 col-12 pe-lg-0">
                <div className="contact-info contact-info-border-start d-flex flex-column">
                  <strong className="site-footer-title d-block mt-4 mb-3">
                    {langData['follow_us']}
                  </strong>
                  <ul className="social-icon">
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-envelope" />
                    </li>
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-instagram" />
                    </li>
                    <li className="social-icon-item">
                      <a href="https://t.me/technoconsult_de" className="social-icon-link bi-telegram" />
                    </li>
                    <li className="social-icon-item">
                      <a href="#" className="social-icon-link bi-facebook" />
                    </li>
                  </ul>
                  <strong className="site-footer-title d-block mt-4 mb-3">
                    Techno Consult
                  </strong>
                  <p className="mb-0">
                    {langData['learn_withus']}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12 ps-lg-0">
                <div className="contact-info d-flex flex-column">
                  <strong className="site-footer-title d-block mb-3">
                    {langData['main_branch']}
                  </strong>
                  <p className="mb-2">
                    Namangan shahri, A.Xo'jayev k. 38A-uy. "Ishga marhamat" monomarkaz binosi
                  </p>
                  <strong className="site-footer-title d-block mt-4 mb-3">
                    {langData['mail']}
                  </strong>
                  <p>
                    <a href="mailto:hello@josh.design">admin@technoconsult.uz</a>
                  </p>
                  <strong className="site-footer-title d-block mt-4 mb-3">
                    {langData['number']}
                  </strong>
                  <p className="mb-0">
                    <a href="tel: 99 995 50 36">+998(99)995-50-36</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                <form action="#" method="get" className="custom-form contact-form" role="form">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input type="text" name="name" id="name" className="form-control" placeholder="Name" required />
                        <label htmlFor="name">{langData['full_name']}</label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input type="email" name="email" id="email" className="form-control" placeholder="Email address" required />
                        <label htmlFor="email">{langData['mail']}</label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input type="number" name="number" id="number" className="form-control" placeholder="Phone number" required />
                        <label htmlFor="number">{langData['number']}</label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input type="number" name="age" id="age" className="form-control" placeholder="Age" required />
                        <label htmlFor="age">{langData['age']}</label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-12">
                      <div className="form-floating">
                        <textarea className="form-control" id="message" name="message" placeholder="Tell me about the project" defaultValue="" />
                        <label htmlFor="message">{langData['message']}</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 ms-auto">
                      <button type="submit" className="form-control">{langData['send']}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section id="copy-right">
          <div className="copy-right-sec">
            <i className="fa fa-copyright" />
            TechnoConsult 2024 Powerd By{" "}
            <a href="https://t.me/Abubak1r">Qodirov Abubakr</a>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Partners;
